<template>
    <section ref="container" class="block block-products-updates announcement-container" v-bind:class="{ 'is-background-white': !loaded }">
        <div class="container is-fluid is-skeleton" v-if="!loaded">
            <div class="gradient"></div>
            <div class="block-head">
                <div class="block-title">&nbsp;</div>
            </div>
        </div>
        <component :is="component" v-else></component>
    </section>
</template>
<script>
import { ref, nextTick, defineComponent } from 'vue';
import SwiperCore, { Navigation, Mousewheel } from 'swiper/core';

SwiperCore.use([Navigation, Mousewheel]);

export default defineComponent({
    props: {
        id: Number,
        type: String,
        blockName: String,
    },
    setup(props) {
        let inited = false;

        const loaded = ref(false);
        const container = ref(null);
        const component = ref(null);

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (!inited) {
                        inited = true;

                        load();
                    }
                }
            });
        };

        const load = () => {
            $http
                .post($ziggy('api.piece', { slug: 'block-products' }), {
                    id: props.id,
                    type: props.type,
                    blockName: props.blockName
                })
                .then((response) => {
                    const componentName = 'piece-promo-products-' + props.id;

                    window.app.component(componentName, {
                        template: response.data ? response.data : '<i></i>'
                    });

                    component.value = componentName;
                    loaded.value = true;

                    nextTick(() => {
                        const announcement = container.value;
                        const tabs = [];
                        const tabsContent = announcement.querySelectorAll('.tabs-content');



                        tabsContent.forEach((tabContent) => {
                            const swiper = [];

                            tabs.push(tabContent.querySelectorAll('.tab'));

                            tabContent.querySelectorAll('.swiper-container').forEach((element) => {
                                const s = new SwiperCore(element, window.viewedSwiperOptions);

                                swiper.push(s);
                            });
                        });

                        if (window.lazyImages) {
                            window.lazyImages.update();
                        }
                    });
                });
        };

        window.addEventListener('DOMContentLoaded', () => {
            new IntersectionObserver(callback, {
                threshold: 0.1
            }).observe(container.value);
        });

        return {
            container,
            component,
            loaded
        };
    }
});
</script>
