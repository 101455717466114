<template>
    <section ref="container" class="block block-products-updates announcement-container" v-bind:class="{ 'is-background-white': !loaded }">
        <div class="container is-fluid is-skeleton" v-if="!loaded">
            <div class="gradient"></div>
            <div class="block-head">
                <div class="block-title">&nbsp;</div>
            </div>
            <div class="tabs">
                <ul>
                    <li>&nbsp;</li>
                </ul>
            </div>
            <div class="tabs-content">
                <div class="tab">
                    <div class="swiper-container is-block">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="index in 5" :key="index" style="width: 256px; margin-right: 24px">
                                <div class="card product">
                                    <div class="card-inner">
                                        <div class="card-image">
                                            <figure class="image is-4by3">
                                                <img src="/images/blank-image.jpg" />
                                            </figure>
                                        </div>
                                        <div class="card-content">
                                            <div class="card-title">
                                                <br />
                                                <br />
                                            </div>
                                            <div class="card-number-availability">
                                                <div class="card-number">&nbsp;</div>
                                            </div>
                                            <div class="card-offer">
                                                <div class="card-prices">
                                                    <div class="current-price color-regular">
                                                        <span>&nbsp;</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <component :is="component" v-else></component>
    </section>
</template>
<script>
import { ref, nextTick, defineComponent } from 'vue';
import SwiperCore, { Navigation, Mousewheel } from 'swiper/core';

SwiperCore.use([Navigation, Mousewheel]);

export default defineComponent({
    props: {
        id: Number,
        sorted: Boolean,
    },
    setup(props) {
        let inited = false;

        const loaded = ref(false);
        const container = ref(null);
        const component = ref(null);

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (!inited) {
                        inited = true;

                        load();
                    }
                }
            });
        };

        const load = () => {
            $http
                .post($ziggy('api.piece', { slug: 'promo-products' }), {
                    id: props.id,
                    sorted: props.sorted
                })
                .then((response) => {
                    const componentName = 'piece-promo-products-' + props.id;

                    window.app.component(componentName, {
                        template: response.data ? response.data : '<i></i>'
                    });

                    component.value = componentName;
                    loaded.value = true;

                    nextTick(() => {
                        const announcement = container.value;

                        const label = announcement.querySelector('.block-head .dropdown-trigger .dropdown-label');
                        const dropdown = announcement.querySelector('.block-head .dropdown');
                        const dropdownItems = announcement.querySelectorAll('.block-head .dropdown-item');
                        const tabs = [];
                        const tabsItems = announcement.querySelectorAll('.tabs');
                        const tabsLi = [];
                        const swipers = [];
                        const tabsContent = announcement.querySelectorAll('.tabs-content');

                        tabsItems.forEach((tabsItem, tabsIndex) => {
                            const lis = tabsItem.querySelectorAll('li');

                            lis.forEach((li, liIndex) => {
                                li.addEventListener('click', () => {
                                    tabsLi[tabsIndex].forEach((element) => {
                                        element.classList.remove('is-active');
                                    });

                                    li.classList.add('is-active');

                                    tabs[tabsIndex].forEach((tab) => {
                                        tab.classList.add('is-hidden');
                                    });

                                    tabs[tabsIndex][liIndex].classList.remove('is-hidden');
                                    swipers[tabsIndex][liIndex].update();
                                });
                            });

                            tabsLi.push(lis);
                        });

                        tabsContent.forEach((tabContent) => {
                            const swiper = [];

                            tabs.push(tabContent.querySelectorAll('.tab'));

                            tabContent.querySelectorAll('.swiper-container').forEach((element) => {
                                const s = new SwiperCore(element, window.viewedSwiperOptions);

                                swiper.push(s);
                            });

                            swipers.push(swiper);
                        });

                        label.addEventListener('click', () => {
                            dropdown.classList.toggle('is-active');
                        });

                        dropdownItems.forEach((item, dropdownIndex) => {
                            item.addEventListener('click', () => {
                                label.textContent = item.textContent;
                                dropdown.classList.remove('is-active');

                                dropdownItems.forEach((di) => {
                                    di.classList.remove('is-active');
                                });

                                tabsItems.forEach((di) => {
                                    di.classList.add('is-hidden');
                                });

                                tabs[dropdownIndex].forEach((di) => {
                                    di.classList.add('is-hidden');
                                });

                                tabsContent.forEach((di) => {
                                    di.classList.add('is-hidden');
                                });

                                if (tabs[dropdownIndex].length) {
                                    tabs[dropdownIndex][0].classList.remove('is-hidden');
                                }

                                if (tabsItems[dropdownIndex]) {
                                    tabsItems[dropdownIndex].classList.remove('is-hidden');
                                }

                                tabsContent[dropdownIndex].classList.remove('is-hidden');

                                item.classList.add('is-active');

                                swipers[dropdownIndex][0].update();
                            });
                        });

                        window.addEventListener('click', (e) => {
                            if (!e.target.classList.contains('dropdown-label')) {
                                dropdown.classList.remove('is-active');
                            }
                        });

                        if (window.lazyImages) {
                            window.lazyImages.update();
                        }
                    });
                });
        };

        window.addEventListener('DOMContentLoaded', () => {
            new IntersectionObserver(callback, {
                threshold: 0.1
            }).observe(container.value);
        });

        return {
            container,
            component,
            loaded
        };
    }
});
</script>
