<template>
    <div class="footer-subscribe">
        <div class="footer-subscribe-title" v-html="__('shop.footer.wanna_promo')"></div>
        <div class="footer-subscribe-descr" v-text="__('shop.footer.subscribe')"></div>
        <form
            class="footer-subscribe-form"
            @submit="submit"
            v-if="!thanks"
        >
            <input
                type="email"
                :placeholder="__('shop.footer.subscribe_placeholder')"
                class="footer-subscribe-input"
                v-model="model.email"
            >
            <button
                type="submit"
                class="footer-subscribe-submit"
                v-bind:class="{ 'is-loading': loading }"
            >
                <svg class="icon subscribe">
                    <use xlink:href="#btn-arrow"/>
                </svg>
            </button>
        </form>
        <div v-else>
            <div class="content" v-html="__('shop.footer.subscribe_thanks')"></div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';

export default {
    name: "subscribe",
    setup() {
        const model = ref({
            email: ''
        });

        const thanks = ref(false);
        const loading = ref(false);

        const submit = (e) => {
            e.preventDefault();

            loading.value = true;

            $http
                .post($env.forms.subscribe, model.value)
                .then(() => {
                    thanks.value = true;
                })
                .catch(() => {
                    toast(__('shop.partner.error'), {
                        type: 'error'
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
            ;
        };

        return {
            model,
            submit,
            thanks,
            loading
        }
    },
};
</script>