<template></template>
<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'user-id',
    setup() {
        const store = useStore();
        const user = computed(() => store.getters['auth/me']);

        if (typeof gtag !== 'undefined') {
            gtag('set', {
                'user_id': user.value.id
            });
        }
    }
});
</script>