let loading = false;
let postData = {
    original: {
        url: document.location.href,
        title: document.title,
        description: document.querySelector('meta[name="description"]') ? document.querySelector('meta[name="description"]').getAttribute('content') : null,
    },
    load: {},
};

function updatePageInfo(key = 'original') {
    let data = postData[key];

    window.history.replaceState(data.url, data.title, data.url);
    document.title = data.title ?? postData['original']?.title;

    let descriptionMetaTag = document.querySelector('meta[name="description"]');
    let canonicalLinkTag = document.querySelector('link[rel="canonical"]');

    if (descriptionMetaTag) {
        if (data.description) {
            descriptionMetaTag.setAttribute('content', data.description);
        } else {
            descriptionMetaTag.parentNode.removeChild(descriptionMetaTag);
        }
    } else if (data.description) {
        let newDescriptionMetaTag = document.createElement('meta');
        newDescriptionMetaTag.setAttribute('name', 'description');
        newDescriptionMetaTag.setAttribute('content', data.description);

        document.head.appendChild(newDescriptionMetaTag);
    }

    if (canonicalLinkTag && canonicalLinkTag.getAttribute('href') != data.url) {
        canonicalLinkTag.setAttribute('href', data.url);
    }
}

function loadContent() {
    if (!loading) {
        loading = true;

        let postContent = document.querySelector('section.block.block-article');

        if (postContent) {
            $http.get('/blog/load/page?type=' + postContent.dataset.type).then((response) => {
                if (postContent) {
                    postContent.insertAdjacentHTML('afterend', response.data.view);

                    loadPageContent(response.data.data.url).then(({ title, description, contentElement }) => {
                        postData.load.url = response.data.data.url;
                        postData.load.title = title;
                        postData.load.description = description;

                        updatePageInfo('load');

                        window.lazyImages.update();
                    });
                }
            });
        }
    }
}

export function onLoadScrollContent() {
    let footer = document.querySelector('footer');
    let windowHeight = window.innerHeight;
    let scrollY = window.scrollY || window.pageYOffset;
    let newsListSection = document.querySelector('section.block.block-news-list');

    if (!newsListSection && footer && scrollY + windowHeight >= footer.offsetTop) {
        loadContent();
        updatePageInfo('load');
    } else if (newsListSection && scrollY + windowHeight >= newsListSection.offsetTop + 400) {
        loadContent();
        updatePageInfo('load');
    } else {
        updatePageInfo('original');
    }
}

function loadPageContent(url) {
    return fetch(url)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.text();
        })
        .then((html) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');

            const title = doc.querySelector('title').innerText;
            const descriptionMeta = doc.querySelector('meta[name="description"]');
            const description = descriptionMeta ? descriptionMeta.getAttribute('content') : '';

            const contentElement = doc.querySelector('section.block.block-news-list');

            return { title, description, contentElement };
        })
        .catch((error) => console.error('Error loading page:', error));
}
